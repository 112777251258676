import "./styles/main.scss";
import bgDefault from "./assets/bg-default.jpg";
import sidebarLogo from "./assets/sb-logo.png";
import introLogo from "./assets/webdev.webp";
import { Typewriter } from "./js/typewriter.js";
import "./assets/favicon.ico";
const spinner = document.querySelector(".spinner div");
const toggleMenu = document.getElementById("toggle-menu");
const sidebar = document.getElementById("sidebar");
const headerPic = document.getElementById("header-home");
const effect1Color = document.getElementById("effect1");
const effect2Font = document.getElementById("effect2");
const effect3Pick = document.getElementById("effect3");
const effect4Greeting = document.getElementById("effect4");
const headerItems = document.querySelectorAll("#header-home li, h1, h1+.lead");
const newBg = document.getElementById("header-home");
const newBgHover = document.querySelectorAll(
  "#header-home #sidebar ul li, .social .fab"
);
const newBorderColor = document.querySelector("#main-nav a.current");
const scrollingText = document.querySelector(".section-reveal");
const iconColor = document.querySelectorAll("#home-a .specials .fas");
const specialsText = document.querySelectorAll(".specials p");
const intro = document.querySelector("#header-home #intro");
const socialIcons = document.querySelectorAll(".social .fab");
const featuresEven = document.querySelectorAll(
  "#home-b .stats div:nth-child(even)"
);
const featuresOdd = document.querySelectorAll(
  "#home-b .stats div:nth-child(odd)"
);
const newBgElements = document.querySelectorAll(
  "#home-c .process-step, .bottom-line"
);

let cCounter = 1,
  fCounter = 0,
  w = 1313,
  h = 738,
  f = ["Dosis", "Playfair Display", "Montserrat", "Noto Serif Display", "Acme"],
  colors = [
    [
      "hsla(0, 0%, 20%, 1)",
      "hsla(44,100%,50%,1)",
      "hsla(41, 93%, 50%, 1)",
      "hsla(41, 93%, 50%, 0.1)",
    ],
    [
      "hsla(282, 27%, 24%, 1)",
      "hsla(268, 57%, 72%, 1)",
      "hsla(277, 28%, 42%, 1)",
      "hsla(277, 28%, 32%, 0.1)",
    ],
    [
      "hsla(0, 60%, 10%, 1)",
      "hsla(357, 59%, 52%, 1)",
      "hsla(357, 52%, 34%, 1)",
      "hsla(357, 52%, 34%, 0.1)",
    ],
    [
      "hsla(211, 40%, 12%, 1)",
      "hsla(352, 78%, 56%, 1)",
      "hsla(351, 70%, 46%, 1)",
      "hsla(351, 70%, 46%, 0.1)",
    ],
    [
      "#hsla(212, 84%, 12%, 1)",
      "hsla(216, 69%, 48%, 1)",
      "hsla(216, 76%, 34%, 1)",
      "hsla(216, 76%, 34%, 0.1)",
    ],
    [
      "hsla(180, 35%, 17%, 1)",
      "hsla(183, 69%, 48%, 1)",
      "hsla(180, 35%, 41%, 1)",
      "hsla(180, 35%, 21%, 0.1)",
    ],
    [
      "hsla(87, 38%, 15%, 1)",
      "hsla(85, 47%, 47%, 1)",
      "hsla(87, 31%, 35%, 1)",
      "hsla(87, 31%, 25%, 0.9)",
    ],
  ];


// Loading images
const sbImg = document.querySelector("#sidebar-logo");
const introImg = document.querySelector("#intro img");
sbImg.src = sidebarLogo;
introImg.src = introLogo;
headerPic.style.background = `url(${bgDefault}) no-repeat center center/cover`;


// Initialize Typewriter script
function init() {
  const txtElement = document.querySelector(".txt-type");
  const words = JSON.parse(txtElement.getAttribute("data-words"));
  const wait = txtElement.getAttribute("data-wait");
  // Init TypeWriter
  new Typewriter(txtElement, words, wait);
}

// Shows menu
function toggleTheMenu() {
  sidebar.classList.toggle("display-menu");
  toggleMenu.classList.toggle("slide-toggle");
}

// Efect 1 - Change theme color
function changeColor() {
  // change bg color of sidebar first li on click
  effect1Color.style.background = colors[cCounter][1];
  // change underline color of active menu items
  newBorderColor.style.borderColor = colors[cCounter][1];
  // new color for specials icons
  iconColor.forEach((item) => (item.style.color = colors[cCounter][1]));
  // new bg color for main header
  newBg.style.backgroundColor = colors[cCounter][0];
  // new bg color for My creative process title underline and  icons
  newBgElements.forEach((item) => (item.style.backgroundColor = colors[cCounter][1]));
  // change features bg
  featuresEven.forEach((item) => (item.style.backgroundColor = colors[cCounter][1]));
  featuresEven.forEach(
    (item) => (item.style.cssText = `color:white; background:${colors[cCounter][2]}`)
  );
  featuresOdd.forEach(
    (item) => (item.style.cssText = "background:#333; color:white")
  );

  //new color in icon animations with JQuery
  $("#home-c .process-icon").hover(
    function () {
      if (cCounter == 0) {
        this.style.background = colors[6][1];
      } else {
        this.style.background = colors[cCounter - 1][1];
      }
    },
    function () {
      this.style.background = "#333";
    }
  );
  // change color counter
  ++cCounter;
  if (cCounter >= colors.length) cCounter = 0;
}

// Effect 2 - Change font
function changeFont() {
  const headerItemsArr = Array.from(headerItems);
  headerItems.forEach((item) => {
    item.style.fontFamily = `${f[fCounter]}, Arial, sans-serif`;
    if (f == "Montserrat") {
      item.style.fontWeight = "100";
    }
  });
  fCounter++;
  if (fCounter >= f.length) fCounter = 0;
}

// Effect3: Changing background image of header
function changeBackground() {
  // spinner
  spinner.style.display = "block";
  spinner.style.opacity = 1;
  headerPic.style.background = `url('https://source.unsplash.com/random/${w}x${h}') no-repeat center center/cover`;
  w++;
  h++;
  setTimeout(() => {
    spinner.style.display = "none";
    spinner.style.opacity = 0;
  }, 2000);


}

//Effect 4: show intro
const greetingPic = () => {
  intro.style.cssText =
    "transform: translateX(0); transition: transform 1s ease-in-out;";
};

//Displays text when scrolling up
function displayText() {
  const windowHeight = window.innerHeight;
  const revealTop = scrollingText.getBoundingClientRect().top;
  const revealPoint = 80;

  if (revealPoint < windowHeight - revealTop) {
    scrollingText.classList.add("section-active");
  } else {
    scrollingText.classList.remove("section-active");
  }
}

// Event listeners
document.addEventListener("DOMContentLoaded", init);
window.addEventListener("scroll", displayText);
toggleMenu.addEventListener("click", toggleTheMenu);
effect1Color.addEventListener("click", changeColor);
effect3Pick.addEventListener("click", changeBackground);
effect2Font.addEventListener("click", changeFont);
effect4Greeting.addEventListener("click", greetingPic);
// sidebar bg color on hovering
newBgHover.forEach((li) =>
  li.addEventListener("mouseenter", () => {
    li.style.background = colors[cCounter - 1][3];
  })
);
newBgHover.forEach((li) =>
  li.addEventListener("mouseleave", () => {
    li.style.background = "rgba(51,51,51,0.3)";
  })
);
